import MapView from '@arcgis/core/views/MapView'
import Graphic from '@arcgis/core/Graphic'
import EventBus from '@/utils/eventBus'
import Search from '@arcgis/core/widgets/Search'
import Draw from '@arcgis/core/views/draw/Draw'
import Polygon from '@arcgis/core/geometry/Polygon'
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol'
import Geometry from '@arcgis/core/geometry/Geometry'
import supportFeatureSet from '@arcgis/core/rest/support/FeatureSet'
import Point from '@arcgis/core/geometry/Point'
import * as projection from '@arcgis/core/geometry/projection'
import * as webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils'
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine'
import Config from '@arcgis/core/config'
/**
 * Renamed to be able to use JS Map if needed
 */
import { default as ArcgisMap } from '@arcgis/core/Map'
import { MapMode, MapSource } from '@/types/MapEnums'
import AddressCandidate from '@arcgis/core/rest/support/AddressCandidate'
import LocatorSearchSource from '@arcgis/core/widgets/Search/LocatorSearchSource'

import { addressToLocations, locationToAddress } from '@arcgis/core/rest/locator'
import SimpleLineSymbol from '@arcgis/core/symbols/SimpleLineSymbol'
import { HighlightClassFn, ILayer } from '@/components/service/interface'
import PopupTemplate from '@arcgis/core/PopupTemplate'
import { ChlorineLayerService, CrewLayerService, IncidentLayerService, NeighborHoodLayerService, PressureZoneLayerService, WardLayerService, WorkOrderLayerService } from '@/components/service/layer'
import * as reactiveUtils from '@arcgis/core/core/reactiveUtils'
import { ServiceContainer, ServiceName } from '@/components/service/serviceContainer'

const ZOOM_GRAPHIC_NAME = 'zoom-graphic'
const DRAW_GRAPHIC_NAME = 'draw-graphic'
const ARCHIVE_GRAPHIC_NAME = 'archive-graphic'
const ATTACH_GRAPHIC_NAME = 'attach-graphic'
const MAP_CENTER = [-77.04149404953489, 38.883661207100076]
const INITIAL_ZOOM_LEVEL = 12
const INITIAL_ZOOM_LEVEL_FOR_SMALL_SCREEN = 11
const SMALL_SCREEN_HEIGHT_BREAKPOINT = 950
const GEOCODE_URL = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer'
const FEATURE_ZOOM_LEVEL = 18
const SEARCH_WIDGET_ID = 'search-widget-id'
/**
 * Following two constants are used for picking location.
 * If the selected location is more than MAX_DISTANCE_FOR_PICK_LOCATION (MAX_DISTANCE_UNIT)
 * the location is not picked.
 */
const MAX_DISTANCE_FOR_PICK_LOCATION = 5
const MAX_DISTANCE_UNIT = 'miles'

export class MapService {
	private readonly view!: MapView
	private readonly queryRadiusInches = 0.2
	private zoomDrawer: Draw
	private polygonDrawer: Draw
	private archiveDrawer: Draw
	private attachDrawer: Draw
	/**
	 * Since only one instance of MapService, as well as MapView, is created for all the dashboards, we need
	 * to keep a reference to the immediate-click handler. Because it must be removed when Map.vue (or any other component using it)
	 * unmounts to avoid triggering multiple events.
	 * @private
	 */
	private immediateClickHandle?: IHandle
	private serviceContainer: ServiceContainer
	mapMode: MapMode = MapMode.GLOBE
	mapSource: MapSource = MapSource.IncidentTracking

	constructor(mapContainerRef: HTMLElement, serviceContainer: ServiceContainer) {
		this.serviceContainer = serviceContainer
		this.view = this.createMapView(mapContainerRef)
		//
		this.zoomDrawer = new Draw({ view: this.mapView })
		this.archiveDrawer = new Draw({ view: this.mapView })
		this.attachDrawer = new Draw({ view: this.mapView })
		this.polygonDrawer = new Draw({ view: this.mapView })
	}

	get mapView() {
		return this.view
	}

	private get incidentLayerService(): IncidentLayerService {
		return this.serviceContainer.getLayerService(ServiceName.INCIDENT_LAYER_SERVICE) as IncidentLayerService
	}

	private get chlorineLayerService(): ChlorineLayerService {
		return this.serviceContainer.getLayerService(ServiceName.CHLORINE_LAYER_SERVICE) as ChlorineLayerService
	}

	private get crewLayerService(): CrewLayerService {
		return this.serviceContainer.getLayerService(ServiceName.CREW_LAYER_SERVICE) as CrewLayerService
	}

	private get workOrderLayerService(): WorkOrderLayerService {
		return this.serviceContainer.getLayerService(ServiceName.WORK_ORDER_LAYER_SERVICE) as WorkOrderLayerService
	}

	private get activeBaseLayer(): ILayer {
		const pressureZoneLayerService = this.serviceContainer.getLayerService(ServiceName.PRESSURE_ZONE_LAYER_SERVICE) as PressureZoneLayerService
		const wardLayerService = this.serviceContainer.getLayerService(ServiceName.WARD_LAYER_SERVICE) as WardLayerService
		const neighborhoodLayerService = this.serviceContainer.getLayerService(ServiceName.NEIGHBORHOOD_LAYER_SERVICE) as NeighborHoodLayerService

		/**
		 * Only one of the layers is visible at a given moment of time.
		 */
		if (pressureZoneLayerService.getLayer().visible) {
			return pressureZoneLayerService
		} else if (wardLayerService.getLayer().visible) {
			return wardLayerService
		} else {
			return neighborhoodLayerService
		}
	}

	get getMapSource(): MapSource {
		return this.mapSource
	}

	/**
	 * Initializes the services. This method should be called right after the service is created.
	 * add any other initialization logic here.
	 */
	init() {
		this.setLayerReOrderWatcher()
	}

	/**
	 * Watches the changes to the map layers length and reorders the layers according
	 * to a predefined order. We need this to avoid layers, i.e. base layers not being
	 * added on the top of other layers.
	 */
	setLayerReOrderWatcher() {
		reactiveUtils.watch(
			() => this.mapView.map.layers.length,
			() => {
				this.reOrderLayers()
			}
		)
	}

	/**
	 * Sets up the event handlers for mapView.
	 * @param mapClickEventHandler - The event handler to set for the map click event.
	 * @param popupListClickEventHandler - The event handler to set for the popup list click event.
	 * @param setMapScale - Map scale change handler.
	 */
	setEventHandlers(mapClickEventHandler: () => void, popupListClickEventHandler: () => void, setMapScale: (scale: number) => void) {
		this.setMapClickEventHandler(mapClickEventHandler)
		this.setListPopupClickEventHandler(popupListClickEventHandler)
		this.watchMapScale(() => null, setMapScale)
	}

	/**
	 * Initializes the map view.
	 * @param container - The ref for the HTML element that contains the map.
	 */
	createMapView(container: any) {
		// TODO: Get from env
		Config.apiKey = 'AAPKd0490ac99cbb4b7483a39564dc694998cbbkJnD-Zu6HFJqg3rC-NCq-BLtPhH4fQGR93Ko_zbXlx2gpaepTCDiaz3zC4eZm'

		return new MapView({
			map: new ArcgisMap({
				basemap: 'arcgis-topographic'
			}),
			center: MAP_CENTER,
			zoom: this.getInitialMapZoomLevel(),
			container: container,
			ui: {
				components: ['attribution']
			},
			constraints: {
				rotationEnabled: false
			},
			popup: {
				dockEnabled: false,
				dockOptions: {
					buttonEnabled: false,
					breakpoint: false
				},
				actions: [],
				alignment: 'top-right',
				visibleElements: {
					closeButton: true
				},
				autoOpenEnabled: false,
				autoCloseEnabled: false
			}
		})
	}

	/**
	 * Sets the given event handler to the map click event of the popup list.
	 * @param handler - The event handler to set.
	 */
	setListPopupClickEventHandler(handler: (event: MouseEvent) => void) {
		document.addEventListener('click', handler)
	}

	/**
	 * Sets the map mode and removes the highlight graphics of the previous mode.
	 * @param mapMode - The map mode to set.
	 */
	setMapMode(mapMode: MapMode) {
		this.mapMode = mapMode

		if (this.mapMode !== MapMode.ARCHIVE) {
			this.archiveDrawer.activeAction?.destroy()
		}

		if (this.mapMode !== MapMode.ATTACH) {
			this.attachDrawer.activeAction?.destroy()
		}

		if (this.mapMode === MapMode.PAN || this.mapMode === MapMode.GLOBE) {
			this.incidentLayerService.unHighlight()
			this.workOrderLayerService.unHighlight()
			this.archiveDrawer.activeAction?.destroy()
			this.attachDrawer.activeAction?.destroy()
			this.polygonDrawer.activeAction?.destroy()
			this.zoomDrawer.activeAction?.destroy()
		}

		if (this.mapMode !== MapMode.ZOOMIN && this.mapMode !== MapMode.ZOOMOUT) {
			this.zoomDrawer.activeAction?.destroy()
		}
	}

	setMapSource(mapSource: MapSource) {
		this.mapSource = mapSource
	}

	/**
	 * Disables zoom of the view.
	 */
	async disableZoom() {
		function stopEvtPropagation(event: any) {
			event.stopPropagation()
		}

		await this.mapView.when()

		this.mapView.on('mouse-wheel', stopEvtPropagation)
		this.mapView.on('double-click', stopEvtPropagation)
		this.mapView.on('double-click', ['Control'], stopEvtPropagation)
		this.mapView.on('drag', stopEvtPropagation)
		this.mapView.on('drag', ['Shift'], stopEvtPropagation)
		this.mapView.on('drag', ['Shift', 'Control'], stopEvtPropagation)
		this.mapView.on('key-down', function (event) {
			const prohibitedKeys = ['+', '-', 'Shift', '_', '=', 'ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft']
			const keyPressed = event.key
			if (prohibitedKeys.indexOf(keyPressed) !== -1) {
				event.stopPropagation()
			}
		})
	}

	/**
	 * Creates a {@link Search} widget and adds it to the view.
	 * @param withCreateIncident - The map source.
	 */
	async addSearchWidget(withCreateIncident: boolean) {
		const eventDataPropertyName = 'graphic'

		const onCreateIncident = (event: MouseEvent) => {
			const data = (event.target as HTMLElement).dataset[eventDataPropertyName]
			if (data) {
				const parsedData = JSON.parse(data) as Graphic
				// Somehow the parsed graphic doesn't contain the correct geometry
				// So we create a new graphic with the correct geometry
				const graphic = new Graphic({
					attributes: { ...parsedData.attributes },
					geometry: new Point({
						latitude: parsedData.attributes.DisplayY,
						longitude: parsedData.attributes.DisplayX
					})
				})
				EventBus.$emit('create-incident-with-location', graphic)
				this.hidePopup()
			}
		}

		const searchSource = new LocatorSearchSource({
			url: 'https://utility.arcgis.com/usrsvcs/servers/b7b896e7a07545209f75ab81e0987c33/rest/services/World/GeocodeServer?sourceCountry=USA&langCode=en',
			name: 'find',
			placeholder: 'Find address or street',
			maxSuggestions: 6,
			singleLineFieldName: 'SingleLine',
			minSuggestCharacters: 0,
			outFields: ['*']
		})

		if (withCreateIncident) {
			searchSource.popupTemplate = new PopupTemplate({
				overwriteActions: true,
				actions: [],
				title: 'Search Result',
				content: ({ graphic }: { graphic: Graphic }) => {
					const graphicData = JSON.stringify(graphic)

					const mainDiv = document.createElement('div')
					mainDiv.classList.add('search-result')

					const textDiv = document.createElement('div')
					textDiv.innerText = graphic.attributes['LongLabel']
					textDiv.classList.add('search-result--address')
					mainDiv.append(textDiv)
					const btn = document.createElement('button')
					btn.classList.add('search-result--btn')
					btn.dataset[eventDataPropertyName] = graphicData
					btn.onclick = onCreateIncident

					const btnText = document.createElement('span')
					btnText.classList.add('search-result--btn--text')
					btnText.innerText = 'Create Incident'
					btnText.dataset[eventDataPropertyName] = graphicData

					const btnIcon = document.createElement('span')
					btnIcon.classList.add('search-result--btn--icon')
					btnIcon.innerText = '+'
					btnIcon.dataset[eventDataPropertyName] = graphicData

					btn.append(btnText)
					btn.append(btnIcon)
					mainDiv.append(btn)

					return mainDiv
				}
			})
		}

		const searchWidget = new Search({
			id: SEARCH_WIDGET_ID,
			view: this.mapView,
			locationEnabled: false,
			includeDefaultSources: false,
			searchAllEnabled: false,
			sources: [searchSource]
		})

		this.mapView.ui.add(searchWidget, { position: 'top-left' })
	}

	/**
	 * Zooms into the view.
	 */
	zoomIn() {
		this.zoom(true)
	}

	/**
	 * Zooms out of the view.
	 */
	zoomOut() {
		this.zoom(false)
	}

	/**
	 * Zooms in or out of the map. If zoomIn is true, zooms in, otherwise zooms out.
	 * @param zoomIn
	 */
	zoom(zoomIn = true) {
		// create an instance of draw rectangle action
		// the rectangle vertices will be only added when
		// the pointer is clicked on the view
		const action = this.zoomDrawer.create('rectangle')

		// fires when a vertex is added
		action.on('vertex-add', function (evt) {
			measureLine(evt.vertices)
		})

		// fires when the pointer moves
		action.on('cursor-update', function (evt) {
			measureLine(evt.vertices)
		})

		// fires when the drawing is completed
		action.on('draw-complete', async (evt) => {
			// measureLine(evt.vertices)
			this.removeZoomRectangles()

			const graphic = this.createZoomGraphic(evt.vertices)
			if (zoomIn && this.mapMode === MapMode.ZOOMIN) {
				await this.mapView.goTo({ geometry: graphic.geometry })
			} else {
				await this.mapView.goTo({
					geometry: graphic.geometry,
					zoom: this.mapView.zoom - 1
				})
			}

			action.destroy()
			this.zoom(zoomIn)
		})

		// fires when a vertex is removed
		action.on('vertex-remove', function (evt) {
			measureLine(evt.vertices)
		})

		const measureLine = (vertices: any) => {
			this.removeZoomRectangles()

			// let lineLength = geometryEngine.geodesicLength(line, 'miles')
			const graphic = this.createZoomGraphic(vertices)
			this.mapView.graphics.add(graphic)
		}
	}

	/**
	 * Zooms one level into the view.
	 */
	fixedZoomIn() {
		this.mapView.goTo({ zoom: this.mapView.zoom + 1 })
	}

	/**
	 * Zooms out one level out of the view.
	 */
	fixedZoomOut() {
		this.mapView.goTo({ zoom: this.mapView.zoom - 1 })
	}

	/**
	 * Handles {@link MapMode.GLOBE} mode. Centers the map to the globe.
	 */
	async globe() {
		await this.mapView.goTo({ center: MAP_CENTER, zoom: this.getInitialMapZoomLevel() })
	}

	/**
	 * Handles {@link MapMode.EDIT} mode. Handles drawing polygon on the map.
	 * @param onComplete - Callback function to be called when the draw action is completed.
	 */
	drawArea(onComplete: (rings: number[][]) => void) {
		// create an instance of draw polygon action
		// the polygon vertices will be only added when
		// the pointer is clicked on the view
		const action = this.polygonDrawer.create('polygon', { mode: 'click' })

		// fires when a vertex is added
		action.on('vertex-add', function (evt) {
			measureLine(evt.vertices)
		})

		// fires when the pointer moves
		action.on('cursor-update', function (evt) {
			measureLine(evt.vertices)
		})

		// fires when a vertex is removed
		action.on('vertex-remove', function (evt) {
			measureLine(evt.vertices)
		})

		// fires when the drawing is completed
		action.on('draw-complete', async (evt) => {
			this.removeDrawnAreaPolygon()

			const completeGraphic = this.createDrawAreaGraphic(evt.vertices)
			completeGraphic.symbol.set('color', [6, 129, 197, 0.15])
			completeGraphic.symbol.set('outline', {
				style: 'long-dash',
				color: 'blue',
				width: 1
			})

			this.addGraphics([completeGraphic])

			const geoJson = webMercatorUtils.webMercatorToGeographic(completeGraphic.geometry) as Polygon
			const rings = geoJson.rings[0]
			// The first and last points must be the same to close the polygon
			// required by elastic search
			rings.push(rings[0])

			onComplete(rings)
		})

		const measureLine = (vertices: any) => {
			this.removeDrawnAreaPolygon()

			const graphic = this.createDrawAreaGraphic(vertices)
			this.mapView.graphics.add(graphic)
		}
	}

	/**
	 * Handles {@link MapMode.ARCHIVE} mode. Handles drawing rectangles on the map for archive.
	 * @param onComplete - Callback function to be called when the draw action is completed.
	 */
	drawArchive(onComplete: (incidentsResult: supportFeatureSet, woResult: supportFeatureSet) => void) {
		// create an instance of draw polygon action
		const action = this.archiveDrawer.create('rectangle')

		// fires when a vertex is added
		action.on('vertex-add', function (evt) {
			measureLine(evt.vertices)
		})

		// fires when the pointer moves
		action.on('cursor-update', function (evt) {
			measureLine(evt.vertices)
		})

		// fires when a vertex is removed
		action.on('vertex-remove', function (evt) {
			measureLine(evt.vertices)
		})

		// fires when the drawing is completed
		action.on('draw-complete', async (evt) => {
			this.removeArchiveRectangles()
			const graphic = this.createArchiveGraphic(evt.vertices)

			const incidents = await this.incidentLayerService.getFeaturesIntersectingWithDrawnGraphic(graphic)
			const workOrders = await this.workOrderLayerService.getFeaturesIntersectingWithDrawnGraphic(graphic)

			action.destroy()
			this.drawArchive(onComplete)

			onComplete(incidents, workOrders)
		})

		const measureLine = (vertices: any) => {
			this.removeArchiveRectangles()

			const graphic = this.createArchiveGraphic(vertices)
			this.mapView.graphics.add(graphic)
		}
	}

	/**
	 * Handles {@link MapMode.ATTACH} mode. Handles drawing rectangles on the map for attach.
	 * @param onComplete - Callback function to be called when the draw action is completed.
	 */
	drawAttach(onComplete: (incidents: supportFeatureSet, workOrders: supportFeatureSet) => void) {
		// create an instance of draw polygon action
		const action = this.attachDrawer.create('rectangle')

		// fires when a vertex is added
		action.on('vertex-add', function (evt) {
			measureLine(evt.vertices)
		})

		// fires when the pointer moves
		action.on('cursor-update', function (evt) {
			measureLine(evt.vertices)
		})

		// fires when a vertex is removed
		action.on('vertex-remove', function (evt) {
			measureLine(evt.vertices)
		})

		// fires when the drawing is completed
		action.on('draw-complete', async (evt) => {
			this.removeAttachRectangles()

			const graphic = this.createAttachGraphic(evt.vertices)
			const incidents = await this.incidentLayerService.getFeaturesIntersectingWithDrawnGraphic(graphic)
			const workOrders = await this.workOrderLayerService.getFeaturesIntersectingWithDrawnGraphic(graphic)

			action.destroy()
			this.drawAttach(onComplete)

			onComplete(incidents, workOrders)
		})

		const measureLine = (vertices: any) => {
			this.removeAttachRectangles()

			const graphic = this.createAttachGraphic(vertices)
			this.mapView.graphics.add(graphic)
		}
	}

	/**
	 * Creates a polygon shape from the current extent of the mapView
	 * @return - the polygon shape
	 */
	async getSearchGeometryShape() {
		this.removeDrawnAreaPolygon()
		const geoJson = webMercatorUtils.webMercatorToGeographic(Polygon.fromExtent(this.mapView.extent).toJSON()) as Polygon
		const shape = { type: 'Polygon', coordinates: geoJson.rings }

		return shape
	}

	async findAddress(address: string) {
		await projection.load()

		const url = 'https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer/'

		const params = {
			address: {
				SingleLine: `${address}, Washington DC`,
				countryCode: 'USA',
				location: {
					x: -77.04149404953489,
					y: 38.883661207100076
				}
			},

			maxLocations: 1
		}

		try {
			const result = await addressToLocations(url, params)

			if (result?.length) {
				const {
					location: { x, y }
				} = result[0]

				await this.mapView.goTo({ center: [x, y], scale: 2000 })
			}
		} catch (_) {
			EventBus.$emit('toast', {
				msg: 'Something went wrong',
				title: 'Error',
				variant: 'danger'
			})
		} finally {
			// TODO:  handle
			// this.showOV(true)
		}
	}

	async locationToAddress(location: { longitude: number; latitude: number }, url = GEOCODE_URL) {
		try {
			const point = new Point({
				longitude: location.longitude,
				latitude: location.latitude,
				spatialReference: this.mapView.spatialReference
			})

			return await locationToAddress(url, { location: point })
		} catch (error) {
			console.error('Failed to find address: ' + error)
			return null
		}
	}

	async pickLocation(onCancel: () => void, callSetToast: (m: any) => void, onLocationPicked: (loc: AddressCandidate | null) => void) {
		const targetGeometry = this.mapView.viewpoint.targetGeometry as Point

		await projection.load()

		const query = this.activeBaseLayer.getLayer().createQuery()
		query.returnGeometry = true

		const { features } = await this.activeBaseLayer.getLayer().queryFeatures(query)

		if (features.length > 0) {
			let distanceValid = false
			for (let i = 0; i < features.length; i++) {
				const projectionRes = projection.project(targetGeometry, features[i].geometry.spatialReference) as Geometry
				distanceValid = geometryEngine.distance(features[i].geometry, projectionRes, MAX_DISTANCE_UNIT) <= MAX_DISTANCE_FOR_PICK_LOCATION

				if (distanceValid) {
					break
				}
			}
			if (distanceValid) {
				const url = `${GEOCODE_URL}/reverseGeocode?featureTypes=PointAddress,POI,StreetInt,StreetAddress`
				const location = { latitude: targetGeometry.latitude, longitude: targetGeometry.longitude }

				try {
					const address = await this.locationToAddress(location, url)

					if (address?.location) {
						address.location.latitude = location.latitude
						address.location.longitude = location.longitude

						onLocationPicked(address)
					}
				} catch (error) {
					callSetToast({ text: 'Something went wrong' })
				}

				onCancel()
			} else {
				callSetToast({ text: 'Please set the location no farther than 5 miles from DC borders!' })
			}
		} else {
			callSetToast({ text: 'Something went wrong' })
			onLocationPicked(null)
		}
	}

	watchMapScale(onRemoveGraphics: () => void, onSetScale: (scale: number) => void) {
		this.view.watch('scale', (val) => {
			onSetScale(val)
		})
	}

	/**
	 * Removes all zoom graphics from the map.
	 */
	removeZoomRectangles() {
		this.removeGraphics([ZOOM_GRAPHIC_NAME])
	}

	/**
	 * Removes all archive graphics from the map.
	 */
	removeArchiveRectangles() {
		this.removeGraphics([ARCHIVE_GRAPHIC_NAME])
	}

	/**
	 * Removes all polygon draw area graphics from the map.
	 */
	removeDrawnAreaPolygon() {
		this.removeGraphics([DRAW_GRAPHIC_NAME])
	}

	/**
	 * Removes all attach graphics from the map.
	 */
	removeAttachRectangles() {
		// archive and attach use the same graphic
		this.removeArchiveRectangles()
	}

	/**
	 * Removes all graphics with the given names from the map.
	 * @param graphicNames - Names of the graphics to be removed.
	 */
	removeGraphics(graphicNames: string[]) {
		const graphicsToRemove = this.mapView.graphics.filter((graphic) => graphicNames.includes(graphic.attributes?.graphicType))
		this.mapView.graphics.removeMany(graphicsToRemove)
	}

	removeAllGraphics() {
		this.mapView.graphics.removeAll()
	}

	/**
	 * Sets a click event handler for the view's 'immediate-click' event.
	 * Queries the layers for features at the clicked location.
	 * @param handler - Callback function to be called when the click event is fired.
	 */
	public setMapClickEventHandler(handler: (event: __esri.ViewImmediateClickEvent) => void) {
		this.immediateClickHandle = this.mapView.on('immediate-click', async (event) => {
			if (this.mapMode === MapMode.EDIT) {
				return
			}

			const crewLayer = this.crewLayerService.getLayer()
			const chlorineLayer = this.chlorineLayerService.getLayer()
			const incidentLayer = this.incidentLayerService.getLayer()
			const workOrderLayer = this.workOrderLayerService.getLayer()

			const response = await this.mapView.hitTest(event, { include: [incidentLayer, chlorineLayer, crewLayer, workOrderLayer] })

			this.chlorineLayerService.clearHitTestResults()
			this.crewLayerService.clearHitTestResults()
			this.incidentLayerService.clearHitTestResults()
			this.workOrderLayerService.clearHitTestResults()

			/**
			 * Create a map of layers that are included in the hit test results
			 */
			const layersInclusionMap = new Map<string, Graphic>()
			response.results.forEach((res) => {
				if (res.type === 'graphic') {
					layersInclusionMap.set(res.layer.id, res.graphic)
				}
			})

			/**
			 * handle hiteTest query only for the layers that are included in the hitTest result
			 */
			if (layersInclusionMap.has(this.chlorineLayerService.getLayer().id)) {
				await this.chlorineLayerService.handleHitTest(event, this.queryRadiusInches)
			}

			if (layersInclusionMap.has(this.crewLayerService.getLayer().id)) {
				await this.crewLayerService.handleHitTest(event, this.queryRadiusInches)
			}

			if (layersInclusionMap.has(this.incidentLayerService.getLayer().id)) {
				await this.incidentLayerService.handleHitTest(event, this.queryRadiusInches)
			}

			if (layersInclusionMap.has(this.workOrderLayerService.getLayer().id)) {
				await this.workOrderLayerService.handleHitTest(event, this.queryRadiusInches)
			}

			handler(event)
		})
	}

	/**
	 * Creates a popup for list of features.
	 * @param incidentHighlightClassFn - A function that creates appropriate highlight class for an entry in
	 * the list. Refer to {@link IClicableLayer#createListPopupEntries} for more info.
	 * @param event
	 */
	async showListPopup(event: __esri.ViewImmediateClickEvent, incidentHighlightClassFn: HighlightClassFn, workOrderHighlightFn: HighlightClassFn, crewHighlightFn: HighlightClassFn) {
		const div = document.createElement('div')

		div.innerHTML += this.incidentLayerService.createListPopupEntries(incidentHighlightClassFn)

		div.innerHTML += this.chlorineLayerService.createListPopupEntries()

		div.innerHTML += this.workOrderLayerService.createListPopupEntries(workOrderHighlightFn)

		div.innerHTML += this.crewLayerService.createListPopupEntries(crewHighlightFn)

		const { y, x } = event.mapPoint

		const location = new Point({
			x,
			y,
			spatialReference: this.mapView.spatialReference
		})

		const popupTemplate = new PopupTemplate({
			content: () => div,
			actions: [],
			overwriteActions: true
		})

		const g1 = new Graphic()
		g1.popupTemplate = popupTemplate as any
		const graphics = [g1]
		this.mapView.popup.highlightEnabled = false
		this.mapView.popup.features = graphics
		this.mapView.popup.location = location
		this.mapView.popup.autoOpenEnabled = false

		await this.mapView.goTo({ center: [location.longitude, location.latitude] })

		this.showPopup()
	}

	/**
	 * Creates the graphic used to display the zoom rectangle.
	 * @param vertices - The vertices of the zoom rectangle.
	 * @returns The generated graphic
	 */
	createZoomGraphic(vertices: any) {
		const graphic = new Graphic({
			geometry: new Polygon({
				rings: vertices,
				spatialReference: this.mapView.spatialReference
			}),
			symbol: new SimpleFillSymbol({
				color: 'rgba(0, 0, 0, 0.3)',
				style: 'solid',
				outline: new SimpleLineSymbol({
					color: 'white',
					width: 1
				})
			})
		})

		graphic.geometry = graphic.geometry.extent
		graphic.attributes = { graphicType: ZOOM_GRAPHIC_NAME }

		return graphic
	}

	/**
	 * Creates the graphic used to display the draw area polygon.
	 * @param vertices - The vertices of the polygon.
	 * @returns The generated graphic
	 */
	createDrawAreaGraphic(vertices: any) {
		const polygon = new Polygon({
			rings: vertices,
			spatialReference: this.mapView.spatialReference
		})

		const graphic = new Graphic({
			geometry: polygon,
			symbol: new SimpleFillSymbol({
				color: [0, 0, 0, 0.3],
				style: 'solid',
				outline: new SimpleLineSymbol({
					color: 'red',
					width: 1
				})
			})
		})

		graphic.attributes = { graphicType: DRAW_GRAPHIC_NAME }

		return graphic
	}

	/**
	 * Creates the graphic used to display the archive rectangle.
	 * @param vertices - The vertices of the rectangle.
	 */
	createArchiveGraphic(vertices: any) {
		const graphic = new Graphic({
			geometry: new Polygon({
				rings: vertices,
				spatialReference: this.mapView.spatialReference
			}),
			symbol: new SimpleFillSymbol({
				color: 'rgba(105, 168, 236, 0.3)',
				style: 'solid',
				outline: new SimpleLineSymbol({
					color: '#006BA7',
					width: 1
				})
			})
		})

		graphic.attributes = {}

		graphic.geometry = graphic.geometry.extent
		graphic.attributes = { graphicType: ARCHIVE_GRAPHIC_NAME }

		return graphic
	}

	/**
	 * Creates the graphic used to display the attach rectangle.
	 * @param vertices - The vertices of the rectangle.
	 */
	createAttachGraphic(vertices: any) {
		// archive and attach graphics have same properties.
		return this.createArchiveGraphic(vertices)
	}

	/**
	 * Removes all drawn graphics from the map.
	 */
	clearDrawnGraphics() {
		this.removeGraphics([DRAW_GRAPHIC_NAME, ATTACH_GRAPHIC_NAME, ARCHIVE_GRAPHIC_NAME])
	}

	/**
	 * Adds the given graphic to the view
	 * @param graphic - The graphic to add
	 */
	addGraphics(graphic: Graphic[]) {
		this.mapView.graphics.addMany(graphic)
	}

	/**
	 * Hides the popup if it is visible
	 */
	hidePopup() {
		this.mapView.popup.close()
	}

	isPopupVisible() {
		return this.mapView.popup.visible
	}

	/**
	 * Makes the popup visible
	 */
	showPopup() {
		this.mapView.popup.visible = true
	}

	/**
	 * Zooms to the given graphic
	 * @param feature - The graphic to zoom to
	 */
	async zoomTo(feature: Graphic) {
		return await this.mapView.goTo({ target: feature.geometry, zoom: FEATURE_ZOOM_LEVEL }, { animate: true })
	}

	/**
	 * Removes handles set on the MapView.
	 */
	removeHandles() {
		this.immediateClickHandle?.remove()
	}

	async resetMapView() {
		this.removeHandles()
		this.removeAllGraphics()
		this.mapView.ui.remove(SEARCH_WIDGET_ID)
		await this.globe()
	}

	/**
	 * Reorders the layers in the map to the correct order.
	 * Base layers are always at the bottom.
	 */
	reOrderLayers() {
		const services = this.serviceContainer.layerServices
		this.mapView.map.reorder(services.get(ServiceName.PRESSURE_ZONE_LAYER_SERVICE)!.getLayer(), 0)
		this.mapView.map.reorder(services.get(ServiceName.WARD_LAYER_SERVICE)!.getLayer(), 1)
		this.mapView.map.reorder(services.get(ServiceName.NEIGHBORHOOD_LAYER_SERVICE)!.getLayer(), 2)
		this.mapView.map.reorder(services.get(ServiceName.WATER_MAINS_LAYER_SERVICE)!.getLayer(), 3)
		this.mapView.map.reorder(services.get(ServiceName.CONSTRUCTION_SITES_LAYER_SERVICE)!.getLayer(), 4)
		this.mapView.map.reorder(services.get(ServiceName.CREW_LAYER_SERVICE)!.getLayer(), 5)
		this.mapView.map.reorder(services.get(ServiceName.INCIDENT_LAYER_SERVICE)!.getLayer(), 6)
		this.mapView.map.reorder(services.get(ServiceName.WORK_ORDER_LAYER_SERVICE)!.getLayer(), 7)
		this.mapView.map.reorder(services.get(ServiceName.LIVE_STREAM_WORK_ORDER_LAYER_SERVICE)!.getLayer(), 8)
		this.mapView.map.reorder(services.get(ServiceName.PUMP_STATIONS_LAYER_SERVICE)!.getLayer(), 9)
		this.mapView.map.reorder(services.get(ServiceName.PRESSURE_SENSOR_POINT_LAYER_SERVICE)!.getLayer(), 10)
		this.mapView.map.reorder(services.get(ServiceName.RESERVOIR_AND_TANK_LAYER_SERVICE)!.getLayer(), 11)
		this.mapView.map.reorder(services.get(ServiceName.EVENT_SOLID_LAYER_SERVICE)!.getLayer(), 12)
		this.mapView.map.reorder(services.get(ServiceName.EVENT_TRANSPARENT_LAYER_SERVICE)!.getLayer(), 13)
		this.mapView.map.reorder(services.get(ServiceName.DAM_LAYER_SERVICE)!.getLayer(), 14)
		this.mapView.map.reorder(services.get(ServiceName.CHLORINE_LAYER_SERVICE)!.getLayer(), 15)
		this.mapView.map.reorder(services.get(ServiceName.RAIN_LAYER_SERVICE)!.getLayer(), 16)
		this.mapView.map.reorder(services.get(ServiceName.GAGE_HEIGHT_LAYER_SERVICE)!.getLayer(), 17)
	}

	getInitialMapZoomLevel() {
		return window.innerHeight < SMALL_SCREEN_HEIGHT_BREAKPOINT ? INITIAL_ZOOM_LEVEL_FOR_SMALL_SCREEN : INITIAL_ZOOM_LEVEL
	}
}
